<template>
  <div class="animated fadeIn">
    <CardTable :title="title" :columns="table.columns" :rows="table.rows" :isLoading="table.isLoading"
               :pageSize="serverParams.pageSize" :pageNumber="serverParams.pageNumber" :totalPage="table.totalPage"
               :totalRecords="table.totalRecords" @onRowSelected="onRowSelected" @onPageChange="onPageChange">
      <template #action>
        <div class="card-header-actions">
          <b-row class="d-flex flex-row-reverse">
            <b-col sm="3">
              <BuildingDropdown :server-params="serverParams"
                                :on-select="getAll" />
            </b-col>
          </b-row>
          <b-button :to="{ name: 'EmailTemplateCircular' }" size="sm" variant="primary" class="mr-2">
            Circular
          </b-button>
          <b-button :to="{ name: 'EmailTemplateCreate' }" size="sm" variant="primary" class="mr-2">
            Create New
          </b-button>
        </div>
      </template>
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(isActive)="data">
        <span v-show="data.item.isActive" class="text-success">Active</span>
        <span v-show="!data.item.isActive" class="text-danger">InActive</span>
      </template>
      <template #cell(action)="data">
        <b-button @click="update(data)" size="sm">Edit</b-button>
      </template>
    </CardTable>
  </div>
</template>


<script>
  import { EmailTemplate as columns } from "@/shared/columns";
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  export default {
    data: () => ({
      title: "Email Template",
      serverParams: {
        pageNumber: 1,
        pageSize: 100,
        keyword: null,
      },
      table: {
        isLoading: false,
        columns,
        rows: [],
        totalPage: 0,
        totalRecords: 0,
      },
    }),
    created() {
      const self = this;
      self.getAll();
    },
    components: {
      BuildingDropdown,
    },
    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onRowSelected(items) {
        console.log(items)
        const self = this;
        self.$router.push({
          name: "EmailTemplateUpdate",
          params: {
            id: items[0].id,
          },
        });
      },
      onPageChange(params) {
        this.updateParams({ pageNumber: params });
        this.getAll();
      },
      getAll() {
        const self = this;

        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: "/emailtemplate",
            params: self.serverParams,
          })
          .then((response) => {

            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.table.rows = response.data.data;
              self.table.totalPage = response.data.totalPage;
              self.table.totalRecords = response.data.totalData;
            }
            self.table.isLoading = false;
          });
      },
      update(params) {
        const self = this;
        self.$router.push({
          name: "EmailTemplateUpdate",
          params: {
            id: params.item.id,
          },
        });
      },
    },
  };
</script>
